import { AnyAction } from 'redux';
import {
  AuthState,
  AuthTokenType,
  GET_AUTH_DATA_SUCCESS,
  GET_USER_INFO_SUCCESS,
  LOGOUT_SUCCESS,
  VERIFY_LOGIN_OTP_SUCCESS,
} from '../types/auth';

const initState: Partial<AuthState | AuthTokenType> = {
  token: null,
};
function authReducer(state = initState, action: AnyAction): Partial<AuthState | AuthTokenType> {
  switch (action.type) {
    case VERIFY_LOGIN_OTP_SUCCESS:
    case GET_USER_INFO_SUCCESS:
    case GET_AUTH_DATA_SUCCESS: {
      return {
        ...state,
        ...action.body,
      };
    }
    case LOGOUT_SUCCESS: {
      return { ...initState };
    }
    default:
      return state;
  }
}

export default authReducer;
