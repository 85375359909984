import { Typography, Grid, Hidden, Box } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Redirect, Route, Router, Switch, useHistory, useRouteMatch } from 'react-router';
import { UserManagementCommonLayout } from '../commonComponents';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import UsersList from './users';
import UserDetails from './userDetails';
import FundsList from './schemes';
import { getAdminMakerCheckerParams } from '../../redux-store/actions/userFundsVerification';
import { initialStateForAdminMakerChecker } from '../../redux-store/reducers/queryParamsForAdminMakerChecker';
import { useDispatch } from 'react-redux';
import SchemeDetails from './schemeDetails';
import PlansList from './plans';
import PlanDetails from './planDetails';
import MiscList from './misc';
import LoginRequireRoute from '../../routes/LoginRequireRoute';

function Routes(): JSX.Element {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={path}
          render={() => <Redirect to={`${path}/usersList`} from={location?.pathname} />}
        />
        <Route path={`${path}/usersList`} component={UsersList} />
        <Route path={`${path}/schemesList`} component={FundsList} />
        <Route path={`${path}/user-details/:id`} component={UserDetails} />
        <Route path={`${path}/schemes-details/:id`} component={SchemeDetails} />
        <Route path={`${path}/self-request-user-details/:id`} component={UserDetails} />
        <Route path={`${path}/self-request-scheme-details/:id`} component={SchemeDetails} />
        <Route path={`${path}/plansList`} component={PlansList} />
        <Route path={`${path}/miscellaneousList`} component={MiscList} />
        <Route path={`${path}/plan-details/:id`} component={PlanDetails} />
        <Route path={`${path}/self-request-plan-details/:id`} component={PlanDetails} />
        <LoginRequireRoute path="*" />
      </Switch>
    </Router>
  );
}

function UserManagementLayOutItems({ item, link }: { item: string; link: string }) {
  const history = useHistory();
  const pathLink = history.location.pathname.includes(link);

  const dispatch = useDispatch();
  return (
    <Grid
      item
      sx={{
        py: 2,
        px: 4,
        borderBottom: pathLink ? '3px solid #57B6BA' : 'none',
      }}>
      <Typography
        sx={{
          color: 'primary.main',
          textDecoration: 'none',
          fontWeight: 600,
        }}
        onClick={async () => {
          await dispatch(getAdminMakerCheckerParams(initialStateForAdminMakerChecker));
        }}
        to={{ pathname: `${link}` }}
        component={RouterLink}>
        {item}
      </Typography>
    </Grid>
  );
}

export default function UsersFundsVerificationLayOut(): JSX.Element {
  const history = useHistory();
  const pathName = history.location.pathname;
  const { path } = useRouteMatch();

  const links = [
    `${path}/usersList`,
    `${path}/schemesList`,
    `${path}/plansList`,
    `${path}/miscellaneousList`,
  ];
  const items = [
    { title: 'User(s)', routerLink: `usersList` },
    { title: 'Scheme(s)', routerLink: `schemesList` },
    { title: 'Plan(s)', routerLink: `plansList` },
    { title: 'Miscellaneous', routerLink: `miscellaneousList` },
  ];

  const Menulinks = links.includes(pathName) ? links : [];
  const MenuItems = links.includes(pathName) ? items : [];

  const dispatch = useDispatch();

  return (
    <UserManagementCommonLayout showButton={Menulinks.includes(pathName)}>
      <>
        {Menulinks.includes(pathName) && (
          <>
            <Hidden only="xs">
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                flexWrap="nowrap"
                alignItems="center"
                sx={{
                  backgroundColor: 'white',
                  marginTop: 3,
                  borderRadius: '5px',
                  mb: 0.5,
                }}>
                {MenuItems.map((itemList, index) => {
                  return (
                    <React.Fragment key={index}>
                      <UserManagementLayOutItems item={itemList.title} link={itemList.routerLink} />
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Hidden>
            <Hidden smUp={true}>
              <Box
                sx={{
                  bgcolor: 'white',
                  pb: 4,
                  borderRadius: '5px',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 1,
                    mb: 2,
                    py: 2,
                    pl: { xs: 3, sm: 0 },
                    color: 'primary.main',
                    bgcolor: 'rgba(238, 244, 251, 0.8)',
                  }}>
                  <PersonOutlineOutlinedIcon fontSize="medium" />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                      pl: 1,
                    }}>
                    Users & Funds Verification
                  </Typography>
                </Box>
                <Box sx={{ px: 4, pt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="user-items"></InputLabel>
                    <Select
                      labelId="user-items"
                      value={MenuItems[Menulinks.indexOf(pathName)].routerLink}
                      onChange={async (e) => {
                        await dispatch(
                          getAdminMakerCheckerParams(initialStateForAdminMakerChecker)
                        );
                        history.push(`${e.target.value}`);
                      }}
                      sx={{
                        color: 'primary.main',
                        fontWeight: 500,
                      }}
                      IconComponent={(props) => (
                        <ArrowDropDownIcon
                          {...props}
                          sx={{
                            paddingRight: '13px',
                            color: '#00D9DA !important',
                            cursor: 'pointer',
                          }}
                          fontSize="large"
                        />
                      )}>
                      {MenuItems.map((itemlist, ind) => (
                        <MenuItem
                          sx={{ borderBottom: '1px solid #ccc' }}
                          value={itemlist.routerLink}
                          key={ind}>
                          {itemlist.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Hidden>
          </>
        )}
        <Routes />
      </>
    </UserManagementCommonLayout>
  );
}
